*:focus {
  outline: 0;
  outline: none;
}

@media (min-width: 600px) {

  .slick-initialized .slick-track {
    display: flex;
    align-items: center;
  }
}

@keyframes fadeInUpRight {
  0% {
    opacity: 0;
    transform: translate3d(-10px,30px,0);
  }
  100% {
    opacity: 1; 
    transform: none;
  }
}
@keyframes fadeInUpLeft {
  0% {
    opacity: 0;
    transform: translate3d(10px,30px,0);
  }
  100% {
    opacity: 1; 
    transform: none;
  }
}


@keyframes fadeInLeft {
  0% {
    opacity: 0; 
    transform: translate3d(5%,0,0);
  }

  100% {
    opacity: 1;
    transform: none;
  }
}

@keyframes fadeInRight {
  0% {
    opacity: 0; 
    transform: translate3d(-5%,0,0);
  }

  100% {
    opacity: 1;
    transform: none;
  }
}

@keyframes fadeInLeftIntense {
  0% {
    opacity: 0; 
    transform: translate3d(20%,0,0);
  }

  100% {
    opacity: 1;
    transform: none;
  }
}

@keyframes fadeInRightIntense {
  0% {
    opacity: 0; 
    transform: translate3d(-20%,0,0);
  }

  100% {
    opacity: 1;
    transform: none;
  }
}